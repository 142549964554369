import { createContextStore, thunkOn, action, thunk } from 'easy-peasy';
import cancellable from '@vdx-platform/core/shared/store/cancellable_thunk';
import sendThunkHelper from '@vdx-platform/core/shared/store/send_thunk_helper';
import uploadService from '@vdx-portal/uploads/services/post_files';
import {
  callCancel,
  getChat,
  getClassify,
  getExpiration,
  getSummarize,
  getTranslate,
  postFile,
} from '@vdx-portal/uploads/services/veraAI';
import signableService from '@vdx-portal/signables/services/post_signable';
import invitationsService from '@vdx-platform/core/signables/services/post_signer_invitations';
import { COLORS } from '@vdx-platform/core/signables/consts/create';

const checkCompleteUser = user =>
  !!(user.firstName && user.lastName && user.email && user.hasValidEmail);
const checkCompleteIssuer = issuer => !!issuer.role;

export default createContextStore(
  initialData => ({
    issuer: initialData.issuer,
    activeStep: 0,
    AI: {
      title: undefined,
      results: undefined,
      loadingResult: undefined,
      error: undefined,
      isChat: undefined,
      uploadingFileId: undefined,
      historyChat: {},
    },
    upload: {
      file: undefined,
      name: undefined,
      title: undefined,
      extension: undefined,
      fileId: undefined,
      summarize: undefined,
      hasSummarize: undefined,
    },
    recipients: {
      issuerIsSigner: false,
      issuerUser: {
        role: 'Sender',
        fullName: initialData.userName,
        firstName: initialData.userFirstName,
        lastName: initialData.userLastNames,
        email: initialData.userEmail,
        status: 'signer',
        color: COLORS[0],
        isComplete: true,
      },
      hasErrors: false,
      users: [
        {
          id: 0,
          role: undefined,
          firstName: undefined,
          lastName: undefined,
          email: undefined,
          status: 'signer',
          color: COLORS[1],
          hasValidEmail: false,
          isComplete: false,
          customMessage: {
            hasMessage: false,
            subject: '',
            body: '',
          },
        },
      ],
      lastUserId: 0,
    },
    placeholders: [],
    hasSignatureTrail: true,

    signable: {
      uid: undefined,
    },

    loading: false,
    success: false,
    error: undefined,

    isSubmitting: false,
    submitted: false,
    submitError: undefined,

    setUploadedFile: action((state, file) => {
      callCancel();
      const ext = file.name.match(/\.[^.]+$/)[0];
      state.upload.file = file;
      state.upload.name = file.name;
      state.upload.title = file.name.replace(ext, '');
      state.upload.extension = ext;
      state.upload.fileId = undefined;

      // clear placeholders
      if (state.placeholders.length) {
        state.placeholders = [];
      }
    }),
    updateAIResults: action((state, payload) => {
      state.AI.results = payload.results;
      state.AI.title = payload.title;
      state.AI.loadingResult = payload.loading;
      state.AI.error = payload.error;
      state.AI.loadingChat = payload.loading;
      state.AI.chatResult = payload.chatResult;
      state.AI.isChat = payload.isChat;
      state.AI.uploadingFileId = payload.uploadingFileId;
    }),
    updateSummarizeFile: action((state, payload) => {
      state.upload.summarize = payload;
    }),

    updateFileId: action((state, payload) => {
      state.upload.fileId = payload;
    }),
    addChatHistory: action((state, { fileId, history }) => {
      const localHistory = {};
      localHistory[fileId] = history;
      state.AI.historyChat = { ...state.AI.historyChat, ...localHistory };
    }),
    setHasSummarize: action((state, payload) => {
      // eslint-disable-next-line no-console
      console.log('DEBUG', { payload });
      state.upload.hasSummarize = payload;
    }),
    sendUploadedFileToIAService: thunk(async (actions, file) => {
      // eslint-disable-next-line no-console
      console.log('DEBUG', 'STARTING UPLOAD');

      try {
        actions.updateAIResults({ uploadingFileId: true });

        const updatedFile = new File([file], file.name, {
          type: file.type,
        });

        const data = await postFile(updatedFile);
        actions.updateFileId(data.uuid);

        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error, file });
        actions.updateFileId(undefined);
        actions.updateAIResults({
          error: 'An error occurred. Please try again later.',
        });
      } finally {
        actions.updateAIResults({ uploadingFileId: false });
        actions.summarizeDefault();
      }
    }),
    summarize: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;

      if (!fileId) return;

      try {
        actions.updateAIResults({
          results: undefined,
          title: 'Summarize',
          loading: true,
        });

        // eslint-disable-next-line no-console
        console.log('DEBUG', 'summarize');

        const data = await getSummarize(fileId);

        actions.updateAIResults({
          results: data.text,
          title: 'Summarize',
          loading: false,
        });

        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });
        actions.updateAIResults({
          error: 'An error occurred. Please try again later.',
        });
        actions.updateAIResults({
          title: 'Summarize',
          loading: false,
        });
      }
    }),
    summarizeDefault: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;

      if (!fileId) return;

      try {
        const data = await getSummarize(fileId);

        actions.updateSummarizeFile(data?.text);

        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });
      }
    }),
    expiration: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;

      if (!fileId) return;

      try {
        actions.updateAIResults({
          results: undefined,
          title: 'Important dates',
          loading: true,
        });

        // eslint-disable-next-line no-console
        console.log('DEBUG', 'expiration');

        const data = await getExpiration(fileId);

        actions.updateAIResults({
          results: data.text,
          title: 'Important dates',
          loading: false,
        });

        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });

        actions.updateAIResults({
          title: 'Important dates',
          error: 'An error occurred. Please try again later.',
          loading: false,
        });
      }
    }),
    classify: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;

      if (!fileId) return;

      try {
        actions.updateAIResults({
          results: undefined,
          title: 'Classify',
          loading: true,
        });

        // eslint-disable-next-line no-console
        console.log('DEBUG', 'classify');

        const data = await getClassify(fileId);

        actions.updateAIResults({
          results: data.text,
          title: 'Classify',
          loading: false,
        });
        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });
        actions.updateAIResults({
          title: 'Classify',
          error: 'An error occurred. Please try again later.',
          loading: false,
        });
      }
    }),
    translate: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;

      if (!fileId) return;

      try {
        actions.updateAIResults({
          results: undefined,
          title: 'Translate',
          loading: true,
        });
        // eslint-disable-next-line no-console
        console.log('DEBUG', 'translate');

        const data = await getTranslate(fileId);

        actions.updateAIResults({
          results: data.text,
          title: 'Translate',
          loading: false,
        });
        // eslint-disable-next-line no-console
        console.log('DEBUG', { data });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });
        actions.updateAIResults({
          title: 'Translate',
          error: 'An error occurred. Please try again later.',
          loading: false,
        });
      }
    }),
    assistant: thunk(async (actions, payload, { getState }) => {
      const { fileId } = getState().upload;
      let lastResults = getState().AI.results || [];
      const youMessageObject = {
        sender: 'You',
        msg: payload,
        getMessage: null,
      };

      actions.updateAIResults({
        results: [...lastResults, youMessageObject],
        isChat: true,
        loading: true,
      });

      try {
        const endpoint = await getChat(fileId, payload);

        const newMessageObject = {
          sender: 'Vera Assistant',
          msg: null,
          getMessage: endpoint,
        };

        lastResults = getState().AI.results || [];

        actions.updateAIResults({
          results: [...lastResults, newMessageObject],
          isChat: true,
          loading: false,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('DEBUG', { error });
        actions.updateAIResults({
          error: 'An error occurred. Please try again later.',
          isChat: false,
          loading: false,
        });
      }
    }),
    deleteUploadedFile: action(state => {
      callCancel();
      state.upload.file = null;
      state.upload.name = '';
      state.upload.title = '';
      state.upload.extension = '';
      state.upload.fileId = '';
      state.AI.uploadingFileId = false;

      // clear placeholders
      if (state.placeholders.length) {
        state.placeholders = [];
      }
    }),
    renameUploadedFile: action((state, name) => {
      state.upload.name = name + state.upload.extension;
      state.upload.title = name;
    }),
    addRecipient: action(state => {
      const newUser = {
        id: state.recipients.lastUserId + 1,
        role: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        status: 'signer',
        color: COLORS[state.recipients.users.length + 1],
        hasValidEmail: false,
        isComplete: false,
        customMessage: {
          hasMessage: false,
          subject: '',
          body: '',
        },
      };
      const updatedUsers = [...state.recipients.users, newUser];
      state.recipients.users = updatedUsers;
      state.recipients.lastUserId += 1;
    }),
    deleteRecipient: action((state, index) => {
      const { email } = state.recipients.users[index];
      const updatedUsers = state.recipients.users
        .filter((item, i) => i !== index)
        .map((item, i) => ({ ...item, color: COLORS[i + 1] }));
      state.recipients.users = updatedUsers;

      const updatedPlaceholders = state.placeholders.filter(
        placeholder => placeholder.signer.email !== email
      );
      state.placeholders = updatedPlaceholders;
    }),
    addRecipientFirstName: action((state, { index, value }) => {
      state.recipients.users[index].firstName = value;
      const user = state.recipients.users[index];
      state.recipients.users[index].isComplete = checkCompleteUser(user);
    }),
    addRecipientLastName: action((state, { index, value }) => {
      state.recipients.users[index].lastName = value;
      const user = state.recipients.users[index];
      state.recipients.users[index].isComplete = checkCompleteUser(user);
    }),
    addRecipientRole: action((state, { index, value }) => {
      state.recipients.users[index].role = value;
      const user = state.recipients.users[index];
      state.recipients.users[index].isComplete = checkCompleteUser(user);
    }),
    setRecipientCustomMessage: action((state, { index, body, subject }) => {
      state.recipients.users[index].customMessage.hasMessage = body?.length > 0;
      state.recipients.users[index].customMessage.subject = subject;
      state.recipients.users[index].customMessage.body = body;
    }),
    addIssuerRole: action((state, { value }) => {
      state.recipients.issuerUser.role = value;
      state.recipients.issuerUser.isComplete = checkCompleteIssuer(
        state.recipients.issuerUser
      );
    }),
    addRecipientEmail: action((state, { index, value }) => {
      state.recipients.users[index].email = value;
      const user = state.recipients.users[index];
      state.recipients.users[index].isComplete = checkCompleteUser(user);
    }),
    addRecipientStatus: action((state, { index, value }) => {
      state.recipients.users[index].status = value;
    }),
    setIssuerAsSigner: action((state, value) => {
      state.recipients.issuerIsSigner = value;
      if (!value) {
        const updatedPlaceholders = state.placeholders.filter(
          placeholder =>
            placeholder.signer.email !== state.recipients.issuerUser.email
        );
        state.placeholders = updatedPlaceholders;
      }
    }),
    setRecipientValidEmail: action((state, { index, value }) => {
      state.recipients.users[index].hasValidEmail = value;
      const user = state.recipients.users[index];
      state.recipients.users[index].isComplete = checkCompleteUser(user);
    }),
    setRecipientErrors: action((state, value) => {
      state.recipients.hasErrors = value;
    }),
    setActiveStep: action((state, step) => {
      state.activeStep = step;
    }),
    addPlaceholder: action((state, newValue) => {
      const updatedPlaceholders = [...state.placeholders, newValue];
      state.placeholders = updatedPlaceholders;
    }),
    updatePlaceholder: action((state, { id, updated }) => {
      const index = state.placeholders.findIndex(i => i.id === id);
      state.placeholders[index] = updated;
    }),
    removePlaceholder: action((state, id) => {
      const updatedPlaceholders = state.placeholders.filter(
        item => item.id !== id
      );
      state.placeholders = updatedPlaceholders;
    }),
    syncRecipients: action(state => {
      const updatedPlaceholders = [];
      for (let i = 0; i < state.placeholders.length; i += 1) {
        const pl = state.placeholders[i];
        const user = state.recipients.users.find(
          u => u.email === pl.signer.email
        );
        if (user && user.status === 'signer') {
          updatedPlaceholders.push({
            ...pl,
            signer: {
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              userId: user.id,
              role: user.role,
              color: user.color,
            },
          });
        } else if (state.recipients.issuerUser.email === pl.signer.email) {
          updatedPlaceholders.push({
            ...pl,
            signer: {
              ...pl.signer,
              role: state.recipients.issuerUser.role,
            },
          });
        }
      }
      state.placeholders = updatedPlaceholders;
    }),
    setItemResizing: action((state, { id, isResizing }) => {
      const index = state.placeholders.findIndex(i => i.id === id);
      state.placeholders[index].isResizing = isResizing;
    }),
    setSigner: action((state, { id, user, isIssuer }) => {
      const index = state.placeholders.findIndex(i => i.id === id);
      state.placeholders[index].signer = user;
      state.placeholders[index].isIssuer = isIssuer;
    }),
    setSignatureTrail: action((state, value) => {
      state.hasSignatureTrail = value;
    }),
    ...sendThunkHelper('file', (actions, payload, { getState }) => {
      const { file, name, fileId, summarize } = getState().upload;
      const updatedFile = new File([file], name, {
        type: file.type,
        fileId,
        summarize,
      });
      return cancellable(uploadService)(getState().issuer, updatedFile, {
        // eslint-disable-next-line babel/camelcase
        ignore_duplicate: true,
        // eslint-disable-next-line babel/camelcase
        summarize,
      });
    }),
    ...sendThunkHelper(
      'signable',
      (
        actions,
        { fileId, title, placeholders, recipients, hasSignatureTrail, signers },
        { getState }
      ) => {
        const { upload } = getState();

        return cancellable(signableService)(getState().issuer, {
          fileId,
          title,
          placeholders,
          recipients,
          hasSignatureTrail,
          signers,
          // eslint-disable-next-line babel/camelcase
          has_summary: upload.hasSummarize || false,
          // eslint-disable-next-line babel/camelcase
          ocr_file_id: upload.fileId,
          summary: upload.summarize,
        });
      }
    ),
    onSendSignableFileSuccess: thunkOn(
      actions => actions.sendFile.successType,
      (actions, target, { getState }) => {
        const state = getState();
        const signersByEmail = {};
        let signerIdCounter = 1;
        const mappedP = state.placeholders.map(item => {
          if (!signersByEmail[item.signer.email]) {
            const signer =
              state.recipients.users.find(i => i.email === item.signer.email) ||
              {};
            if (signer?.customMessage?.hasMessage) {
              signersByEmail[item.signer.email] = {
                id: signerIdCounter,
                // eslint-disable-next-line babel/camelcase
                is_issuer: item.isIssuer,
                name: item.signer.name,
                email: item.signer.email,
                message: {
                  subject: signer.customMessage.subject,
                  body: signer.customMessage.body,
                },
              };
            } else {
              signersByEmail[item.signer.email] = {
                id: signerIdCounter,
                // eslint-disable-next-line babel/camelcase
                is_issuer: item.isIssuer,
                name: item.signer.name,
                email: item.signer.email,
              };
            }
            signerIdCounter += 1;
          }
          return {
            type: item.type,
            page: item.page,
            // eslint-disable-next-line babel/camelcase
            coordinate_x: item.realLeft,
            // eslint-disable-next-line babel/camelcase
            coordinate_y: item.realTop,
            width: item.realWidth,
            height: item.realHeight,
            // eslint-disable-next-line babel/camelcase
            signer_id: signersByEmail[item.signer.email].id,
          };
        });
        const mappedR = state.recipients.users
          .filter(user => user.status === 'cc')
          .map(user => ({
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
          }));
        actions.sendSignable({
          fileId: state.file.id,
          title: state.upload.title,
          signers: Object.entries(signersByEmail).map(entry => entry[1]),
          placeholders: mappedP,
          recipients: mappedR,
          hasSignatureTrail: state.hasSignatureTrail,
        });
      }
    ),
    ...sendThunkHelper('invitations', (actions, uid, { getState }) =>
      cancellable(invitationsService)(getState().issuer, uid)
    ),
    onSignableCreated: thunkOn(
      actions => actions.sendSignable.successType,
      (actions, target, { getState }) => {
        if (!getState().recipients.issuerIsSigner) {
          actions.sendInvitations(getState().signable.uid);
        }
      }
    ),
  }),
  { name: 'CreateSignable' }
);

/* eslint-disable babel/camelcase */
import httpClient, {
  CancelToken,
} from '@vdx-platform/core/api/client/clientAI';

let cancel;

export const postFile = async file => {
  const formData = new FormData();
  formData.append('file', file);

  const response = await httpClient.post(`ai/document/upload-and-process`, formData, {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response;
};

const getResult = async (path = '', fileId = undefined, message = undefined) => {
  const url = `ai/document/${fileId}/${path}`;

  if (message) {
    url.concat(`?message=${message}`);
  }

  const response = await httpClient.patch(url.toString(), {
    cancelToken: new CancelToken(function executor(c) {
      cancel = c;
    }),
  });

  return response;
};

export const getSummarize = (fileId = undefined) =>
  getResult('summarize', fileId);

export const getClassify = (fileId = undefined) =>
  getResult('classify', fileId);

export const getTranslate = (fileId = undefined) =>
  getResult('translate', fileId);

export const getExpiration = (fileId = undefined) =>
  getResult('date-analyze', fileId);

export const getChat = async (
  fileId = undefined,
  message = undefined,
  signal = undefined
) => {
  const url = new URL(`https://ai-java.veridical.tech/api/v1/ai/chat/streaming/${fileId}`);

  if (message) {
    url.searchParams.append('message', message);
  }

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    signal,
  });

  return response;
};

export const callCancel = () => {
  if (cancel !== undefined) {
    cancel();
  }
};
